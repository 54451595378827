#root {
  width: 100vw;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background {
  border-color: #7768a6 !important;
  background-color: #7768a6 !important;
}

.mdc-checkbox.mdc-checkbox--selected::before,
.mdc-checkbox.mdc-checkbox--selected::after {
  background-color: #7768a6 !important;
}

.mdc-checkbox
  .mdc-checkbox__native-control:checked
  ~ .mdc-checkbox__background::before,
.mdc-checkbox
  .mdc-checkbox__native-control:indeterminate
  ~ .mdc-checkbox__background::before {
  background-color: #7768a6 !important;
}

.mdc-radio
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: #7768a6 !important;
}

.mdc-radio
  .mdc-radio__native-control:enabled
  + .mdc-radio__background
  .mdc-radio__inner-circle {
  border-color: #7768a6 !important;
}

.mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate)
  ~ .mdc-checkbox__background {
  border-color: #5763a2 !important;
  background-color: transparent !important;
}

.mdc-radio
  .mdc-radio__native-control:enabled:not(:checked)
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: #5763a2 !important;
}

.mdc-ripple-surface--accent::before,
.mdc-ripple-surface--accent::after {
  border-color: #5763a2 !important;
}

.mdc-text-field .mdc-text-field__input {
  border-color: #5763a2 !important;
}

.mdc-ripple-upgraded--foreground-activation {
  border-color: #5763a2 !important;
  background-color: #5763a2 !important;
  color: #5763a2 !important;
} */

input[type='text'] {
  border: 1px solid lightgrey;
  margin-top: 0.5rem;
  width: 16rem;
  min-height: 2rem;
  padding: 0.5rem;
  font-size: 1rem;
}

input[type='text']:focus {
  outline-color: #5763a2;
  outline-style: solid;
  outline-width: 1px;
  outline-offset: 1px;
  border: none;
}

label {
  margin-left: 0.5rem;
}

@media (min-width: 600px) {
  input[type='text'] {
    width: 35rem;
  }
}
